<template>
  <v-container fill-height>
    <v-row align="center">
      <v-col v-for="n in partners"
             :key="n.src"
             sm="6"
             md="4"
             lg="4"
             class="">
        <a :href="n.link" target="_blank">
          <v-img :src="n.src"
                 :class="n.bg"
                 max-width="300px">
          </v-img>
        </a>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>


export default {
  name: 'MainPartners',
  components: {},
  computed: {},
  data: () => ({
    partners: [
      {src: require('../assets/img/partners/logo_fri.png'), link:'https://www.foreningenfri.no/', color: 'white', bg: 'white ml-lg-10 ml-md-10 ml-2'},
      {src: require('../assets/img/partners/logo_rosa_kompetanse.png'),link:'https://www.foreningenfri.no/rosa-kompetanse/', color: 'white', bg: 'white ml-lg-10 ml-md-10 ml-2'},
      {src: require('../assets/img/partners/logo_pfk.png'), link:'https://kjonnsinkongruens.no/', color: 'white', bg: 'white ml-lg-10 ml-md-10 ml-2'},
      {src: require('../assets/img/partners/logo_sk.png'), link:'https://skeivungdom.no/', color: 'white', bg: 'white ml-lg-10 ml-md-10 ml-2'},
      {src: require('../assets/img/partners/logo_skeiv_chat.png'), link:'https://www.helseutvalget.no/skeivchat', color: 'white', bg: 'white ml-lg-10 ml-md-10 ml-2'},
      {src: require('../assets/img/partners/logo_ungdomstelefonen.png'), link:'https://ungdomstelefonen.no/', color: 'white', bg: 'white ml-lg-10 ml-md-10 ml-2'},
      {src: require('../assets/img/partners/logo_stiftelsen_steensveen.png'), link:'https://www.stensveen.no', color: 'white', bg: 'white ml-lg-10 ml-md-10 ml-2'},
      {src: require('../assets/img/partners/ung_no.png'), link:'https://www.ung.no', color: 'white', bg: 'white ml-lg-10 ml-md-10 ml-2'},
      {src: require('../assets/img/partners/logo_skv.png'), link:'https://www.skeivverden.no', color: 'white', bg: 'primary ml-lg-10 ml-md-10 ml-2'},
    ],

  }),
};
</script>
