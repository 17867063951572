<template>
  <v-container fluid>
    <div class="row">
      <div class="col" style="width: 100vw">
        <iframe  width="100%" height="700" style="border:0;" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1998.98930230303!2d10.775485515988743!3d59.932319681873864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46416e40b02fd7a5%3A0x517f13ad384eef7c!2siChatten!5e0!3m2!1sno!2sno!4v1656352978980!5m2!1sno!2sno" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>
  </v-container>
</template>
<script>


export default {
  name: 'MainMap',
  components: {},
  computed: {},
  data: () => ({
  }),
};
</script>
