<template>
  <v-container>
    <v-row>
      <v-col
        v-for="person in people"
        :key="person.name"
        cols="12"
        sm="6"
        md="4">
        <v-card style="border-radius: 3%; " elevation="1" class="text-center pt-10" min-height="400">
          <v-img :src="person.link" class="rounded-circle  mx-auto" height="170" width="170" elevation="2"/>


          <v-card-title class="primary-pink justify-center">{{ person.name }}</v-card-title>

          <v-card-text >{{ person.description }}</v-card-text>
        </v-card>


      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'MainPeople',
  components: {},
  computed: {},
  data: () => ({
    people: [
      {
        'name': 'Aleksander',
        'description': 'Aleksander er utdannet sykepleier og jobber til daglig som fagkonsulent på Pro Sentret. Aleksander har lang og bred erfaring fra arbeid med personer med kjønnsinkongruens og andre med skeiv identitet' +
          '\n',
        link: require('../assets/img/people/1.jpg')
      },
      {
        'name': 'Heidi Leona',
        'description': 'Heidi Leona er barnevernspedagog og spesialist i sexologisk veiledning (NACS). Hun har lang erfaring med å jobbe med barn, ungdom og familier.\n' +
          '\n',
        link: require('../assets/img/people/2.png')

      },
      {
        'name': 'Nanna',
        'description': 'Nanna er utdannet sosionom og spesialist i sexologisk rådgivning (NACS). Til daglig jobber hun som leder for Rosa kompetanse helse og sosial der hun holder foredrag om kjønns- og seksualitetsmangfold.\n' +
          '\n',
        link: require('../assets/img/people/3.png')

      },
      {
        'name': 'Luca',
        'description': 'Luca jobber til daglig i FRI- foreningen for kjønns og seksualitetsmangfold. Han har en mastergrad i kjønnsstudier og har lenge jobbet for rettighetene til mennesker med kjønnsinkongruens.\n' +
          '\n',
        link: require('../assets/img/people/4.png')

      },
      {
        'name': 'Ingun',
        'description': 'Ingun er helsesykepleier og spesialist i sexologisk rådgivning i NACS. Hun er avdelingsleder på helsestasjon for kjønn og seksualitet.\n' +
          '\n',
        link: require('../assets/img/people/5.png')

      },
      {
        'name': 'Petter',
        'description': 'Petter er vernepleier med videreutdanning i sexologi. Han har tidligere jobbet med personer med nedsatt funksjonevne, psykisk helse og minoritetsgrupper, og jobber nå på helsestasjonen for kjønn og seksualitet.',
        link: require('../assets/img/people/6.jpg')


      }
    ],
    verticalLogoSrc: require('../assets/img/header/vertical_logo.png'),
    hksLogo: require('../assets/img/header/hks.png'),
    mainLogo: require('../assets/img/header/ichatten.png'),
  }),
};
</script>
