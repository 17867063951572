<template>
  <v-container fill-height class="mb-12">
    <v-row align="center">
      <v-col v-for="n in partners"
             :key="n.src"
             cols="12"
             sm="6"
             md="4"
             class="">
        <a :href="n.link" target="_blank">
          <v-img :src="n.src"
                 :class="n.bg"
                 max-width="300px">
          </v-img>
        </a>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>


export default {
  name: 'MainResources',
  components: {},
  computed: {},
  data: () => ({
    partners: [
      {
        src: require('../assets/img/partners/transhjelpen.png'),
        link: 'https://transhjelpen.no/',
        color: 'white',
        bg: 'white ml-10'
      },
      {
        src: require('../assets/img/partners/logo_hb.png'),
        link: 'https://www.hbrs.no/',
        color: 'white',
        bg: 'white ml-10'
      },
    ],
  }),
};
</script>
