<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        md="4"
      >
        <v-card
          class="mx-auto"
          min-height="260"
          style="border: solid 3px #57C4F2; border-radius: 3%"

        >
          <v-card-title style="color: #57C4F2;">Personvern</v-card-title>
          <v-card-text>
            Vi har taushetsplikt og du er anonym. Skulle det bli fare for liv og helse har vi plikt til å varsle
            etter helsepersonelloven. Etter endt samtale vil du få et evalueringsskjema. Vi setter stor pris på om
            du fyller det ut nettopp for å hjelpe oss til å bli bedre.
          </v-card-text>
          <v-card-actions class="pt-0">
            <v-btn
              text
              style="color: #57C4F2;"
              @click="reveal = false"
            >
              Les mer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-card
          class="mx-auto"
          min-height="260"
          style="border: solid 3px #57C4F2; border-radius: 3%"

        >
          <v-card-title style="color: #57C4F2;">Hva du kan bruke oss til?</v-card-title>
          <v-card-text>
            Du kan få kunnskap om kjønnsmangfold og hjelp til å utforske egen identitet knyttet til kjønn og
            kjønnsuttrykk. Du kan få hjelp til å «komme ut» og være deg selv, samt hjelp til å knytte kontakter til
            gode nettverk og hjelpetilbud i ditt nærområde. Vi kan også veilede foresatte og hjelpere til å bli gode
            støttespillere.


          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-card
          class="mx-auto"
          min-height="260"
          style="border: solid 3px #57C4F2; border-radius: 3%"

        >
          <v-card-title style="color: #57C4F2;">Kunnskapsbank</v-card-title>
          <v-card-text>
            <ul class="list-unstyled" id="knowledge-bank">
              <li>
                <a target="_blank" href="https://www.regjeringen.no/contentassets/d3a092a312624f8e88e63120bf886e1a/rapport_juridisk_kjonn_100415.pdf">Rett
                  til rett kjønn- helse til alle</a></li>
              <li><a target="_blank" href="https://www.wpath.org/media/cms/Documents/SOC%20v7/SOC%20V7_Norwegian.pdf">SOC</a></li>
              <li>
                <a target="_blank" href="https://www.amazon.com/Transgender-Child-Handbook-Families-Professionals/dp/1573443182">The
                  transgender child</a></li>
              <li><a target="_blank" href="https://www.universitetsforlaget.no/nettbutikk/kjonn-i-bevegelse-uf.html">Kjønn i
                bevegelse</a></li>
              <li><a target="_blank" href="https://docplayer.me/47916934-Mannen-kvinnen-meg-1.html">Mannen, kvinnen og meg</a></li>
              <li><a target="_blank" href="https://issuu.com/ganaschehoug/docs/prinsesse_ivar_blaeks">Prinsesse Ivar</a></li>
              <li><a target="_blank" href="https://www.fagbokforlaget.no/Så-falt-brikkene-på-plass/I9788245018455">Så falt
                brikkene på plass</a></li>
              <li><a target="_blank" href="https://www.adlibris.com/no/bok/de-usynlige-kjonn-9788245013863?gclid=Cj0KCQjwiILsBRCGARIsAHKQWLO9d-ubRwBujc7xSYsbMIynrSIj-PpPAWOe9dbeOafYQvr_Y3AhwI8aAroiEALw_wcB">De
                  usynlige kjønn</a></li>

              <li v-for="(item, i) of links" :key="i">
                <a :href="item.link.default" download target="_blank">{{ item.title }}</a>
              </li>
              <li><a href="https://kjonnsinkongruens.no/kjonnsinkongruens/forskning/" target="_blank">Kjønnsbekreftende
                behandling</a></li>
              <li><a target="_blank" href="https://kjonnsinkongruens.no/forskning-pa-ikke-binaere/" >Ikke-binære</a></li>
            </ul>
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>
  </v-container>
</template>
<script>

export default {
  name: 'MainInfo',
  components: {},
  computed: {},
  data: () => ({
    links: [
      {
        link: require('../assets/pdf/Nasjonale faglige retningslinjer HDIR 2020.pdf'),
        title: 'Nasjonale faglige retningslinjer HDIR 2020'
      },
      {
        link: require('../assets/pdf/Levekårsundersøkelsen LHBT 2020_ seksuell_orientering_kjonnsmangfold_og_levekar_resultater_fra_sporreundersokelsen_2020.pdf'),
        title: 'Levekårsundersøkelsen LHBT 2020'
      },
      {
        link: require('../assets/pdf/Organisering og funksjonsfordeling av helsehjelp til personer med kjønnsinkongruens.pdf'),
        title: 'Organisering og funksjonsfordeling av helsehjelp til personer med kjønnsinkongruens'
      },
      {
        link: require('../assets/pdf/PRISER PÅ MASKULINISERENDE OG FEMINISERENDE HORMONER  Fra november 2020.pdf'),
        title: 'Priser på mask. og fem. hormoner November 2020'
      },
      {link: require('../assets/pdf/Rutine feminiserende hormonbehandling HKS EA 18. jan.pdf'), title: 'Rutine fem. hormonbehandling HKS'},
      {link: require('../assets/pdf/Rutine maskuliniserende hormonbehandling HKS 11.01.2020.pdf'), title: 'Rutine mask. hormonbehandling HKS'},
      {link: require('../assets/pdf/WHO europa om IOCD 11.pdf'), title: 'WHO europa om IOCD 11'},
    ]
  }),
};
</script>
