<template>
  <div id="home">
    <Header/>
    <p id="people"
       class="text-center font-weight-regular text-lg-h2 text-md-h2 text-h5 primary-blue mt-12 mb-lg-6 mb-md-6 mb-0">
      Chattere</p>
    <People/>
    <p id="info"
       class="text-center font-weight-regular text-lg-h2 text-md-h2 text-h5 primary-blue mt-12 mb-lg-6 mb-md-6 mb-0">
      Info om oss</p>
    <Info/>
    <p id="partners"
       class="text-center font-weight-regular text-lg-h2 text-md-h2 text-h5 primary-blue mt-12 mb-lg-6 mb-md-6 mb-0">
      Samarbeidspartnere</p>
    <Partners/>
    <p class="text-center font-weight-regular text-lg-h3 text-md-h3 text-h5 primary-blue mt-6 mb-lg-6 mb-md-6 mb-0">
      Andre resursser</p>
    <Resources/>
    <p id="contact"
       class="text-center font-weight-regular text-lg-h2 text-md-h2 text-h5 primary-blue mt-12 mb-lg-6 mb-md-6 mb-0">
      Kontakt oss</p>
    <Contact/>
    <p class="body-1 text-center">Laget av <a href="https://www.linkedin.com/in/h%C3%A5kon-guttulsrud-49080b77/" style="text-decoration: none">Håkon Guttulsrud</a></p>
  </div>
</template>

<script>
import Header from '../components/Header';
import People from '../components/People';
import Info from '../components/Info';
import Partners from '../components/Partners';
import Contact from '../components/Contact';
import Resources from '../components/Resources';

export default {
  name: 'Main-page',
  components: {Header, People, Info, Partners, Resources, Contact},

  data: () => ({}),
};
</script>

<style>
html {
  scroll-behavior: smooth;
}

</style>
