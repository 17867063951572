<template>
    <v-app>
        <NavigationBar/>
        <v-main>
            <router-view class="pl-12 pr-12"></router-view>
        </v-main>
    </v-app>
</template>

<script>
import NavigationBar from "@/components/NavigationBar";

export default {
    name: 'App',
    components: {
        NavigationBar
    },
    data: () => ({}),
};
</script>
