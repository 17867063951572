<template>
  <v-app-bar
    color="white lighten-3"
    app
    elevate-on-scroll
  >
    <router-link class="nav-title" :to="{ path: '/' }">
      <!--      <v-img-->
      <!--        class="mx-2 mb-2 mr-1"-->
      <!--        :src="hksLogo"-->
      <!--        max-height="35"-->
      <!--        max-width="35"-->
      <!--        contain-->
      <!--      ></v-img>-->
      <v-img
        class="mt-2"
        :src="mainLogo"
        max-height="150"
        max-width="150"
        contain
      ></v-img>

    </router-link>


    <v-spacer></v-spacer>
    <v-menu offset-y v-if="$vuetify.breakpoint.xsOnly">
      <template v-slot:activator="{ on, attrs }">

        <v-icon
          color="#57C4F2"
          v-bind="attrs"
          v-on="on"
        >mdi-menu
        </v-icon>
      </template>
      <v-list>
        <v-list-item>
          <router-link class="nav-title" :to="{ path: '/', hash: '#people' }">
            <v-btn text class="btn primary-blue " color="#57C4F2">Chattere</v-btn>
          </router-link>
        </v-list-item>
        <v-list-item>
          <router-link class="nav-title" :to="{ path: '/', hash: '#info' }">
            <v-btn text class="btn primary-blue " color="#57C4F2">Info</v-btn>
          </router-link>
        </v-list-item>
        <v-list-item>
          <router-link class="nav-title" :to="{ path: '/', hash: '#partners' }">
            <v-btn text class="btn primary-blue " color="#57C4F2">Partnere</v-btn>
          </router-link>
        </v-list-item>
        <v-list-item>
          <router-link class="nav-title" :to="{ path: '/plakater' }">
            <v-btn text class="btn primary-blue " color="#57C4F2">Plakater</v-btn>
          </router-link>
        </v-list-item>
        <v-list-item>
          <router-link class="nav-title" :to="{ path: '/personvern' }">
            <v-btn text class="btn primary-blue " color="#57C4F2">Personvern</v-btn>
          </router-link>
        </v-list-item>
      </v-list>
    </v-menu>
    <template v-else>
      <router-link class="nav-title" :to="{ path: '/', hash: '#people' }">
        <v-btn text class="btn primary-blue " color="#57C4F2">Chattere</v-btn>
      </router-link>
      <router-link class="nav-title" :to="{ path: '/', hash: '#info' }">
        <v-btn text class="btn primary-blue " color="#57C4F2">Info</v-btn>
      </router-link>

      <router-link class="nav-title" :to="{ path: '/', hash: '#partners' }">
        <v-btn text class="btn primary-blue " color="#57C4F2">Partnere</v-btn>
      </router-link>

      <router-link class="nav-title" :to="{ path: '/plakater' }">
        <v-btn text class="btn primary-blue " color="#57C4F2">Plakater</v-btn>
      </router-link>
      <router-link class="nav-title" :to="{ path: '/personvern' }">
        <v-btn text class="btn primary-blue " color="#57C4F2">Personvern</v-btn>
      </router-link>
    </template>

  </v-app-bar>
</template>
<script>

import router from '@/router/router';

export default {
  name: 'App',

  data: () => ({
    mainLogo: require('../assets/img/header/ichatten.png'),
    hksLogo: require('../assets/img/header/hks_horizontal.png'),
    color: '',
    items: [
      {title: 'Click Me'},
      {title: 'Click Me'},
      {title: 'Click Me'},
      {title: 'Click Me 2'},
    ],
  }),
  methods: {
    goTo(link) {
      router.push(link);
    }
  }
};
</script>
<style>
.nav-title {
  text-decoration: none;
}

.btn {
  text-transform: unset !important;
}
</style>
