<template>
  <v-container>
    <p  class="text-center font-weight-regular text-lg-h4 text-md-h4 text-h5 primary-blue mb-lg-6 mb-md-6 mb-0">
      Digitale plakater
    </p>
    <v-carousel
      v-if="!isMobile"
      cycle
      height="450"
      hide-delimiter-background
      show-arrows-on-hover
    >
      <v-carousel-item
        v-for="(item,i) in items"
        :key="i"
        :src="item.src"
      ></v-carousel-item>
    </v-carousel>
    <p class="mt-10 font-weight-regular text-lg-h5 text-md-h5 text-h6 primary-blue mb-lg-6 mb-md-6 mb-0">
      Trykk for å laste ned:
    </p>

    <v-row>
      <v-col v-for="i of items" :key="i" lg="4" md="4" sm="6" cols="6">
        <a download :href="i.src"> <v-img :src="i.src" href="public/1.jpg"></v-img></a>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'PosterPage',

  data: () => ({
    dates: [],
    items: [
      {src: require('../assets/img/posters/poster1.jpg')},
      {src: require('../assets/img/posters/poster2.jpg')},
      {src: require('../assets/img/posters/poster3.jpg')},
      {src: require('../assets/img/posters/poster4.jpg')},
      {src: require('../assets/img/posters/poster5.jpg')},
      {src: require('../assets/img/posters/poster6.jpg')},
      {src: require('../assets/img/posters/poster7.jpg')},
      {src: require('../assets/img/posters/poster8.jpg')},
      {src: require('../assets/img/posters/poster9.jpg')},
      {src: require('../assets/img/posters/poster10.jpg')}
    ]
  }),
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    }
  },
};
</script>

<style scoped>

</style>
