<template>
  <div>
    <v-container class="">
      <v-row>
        <v-col v-for="item of items" :key="item.icon" cols="4" class="text-center">
          <a :href="item.link" style="text-decoration: none;">
            <v-icon style="color: #F5B0D0" x-large class="text-center">{{ item.icon }}</v-icon>
            <p v-if="!isMobile" class="grey--text">{{ item.text }}</p>
          </a>
        </v-col>
      </v-row>
    </v-container>
    <Map/>

  </div>
</template>
<script>
import Map from '../components/Map';


export default {
  name: 'MainContact',
  components: {Map},
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    }
  },
  data: () => ({
    items: [
      {'icon': 'mdi-facebook', 'link': 'https://www.facebook.com/pages/category/Health---Wellness-Website/Helsestasjon-for-kj%C3%B8nn-og-seksualitet-268912529818730/', 'text': 'Facebook'},
      {'icon': 'mdi-phone', 'link': 'tel:+4748113013', 'text': 'Telefon'},
      {'icon': 'mdi-email', 'link': 'mailto:hks@bga.oslo.kommune.no', 'text': 'E-post'}
    ]
  }),
};
</script>
<style scoped type="scss">

</style>
