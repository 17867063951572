<template>
  <v-container>
    <p class="text-center font-weight-regular text-lg-h4 text-md-h4 text-h5 primary-blue mb-lg-6 mb-md-6 mb-0">
      Personvern
    </p>
    <v-card>
      <v-card-title>
        Du er anonym når du bruker denne tjenesten
      </v-card-title>
      <v-card-text>
        Personopplysningsloven stiller krav til at det må innhentes spesiell tillatelse til å lagre personopplysninger,
        og bydel Grunerløkka er behandlingsansvarlig for innhenting og bruk av dem.
        <v-spacer></v-spacer>
        IP-adresser vil bli hentet inn i starten av chatten og bli lagret hos vår leverandør som er E dialog.
        Grunnen til dette er at vi må følge helsepersonelloven og har derfor avvergelsesplikt hvis det er fare for liv
        og helse. I en slik situasjon må vi kunne gi informasjon videre til politiet. Det er kun E dialog som har
        tilgang på opplysningene.

        I tilfeller der vi må melde fra om fare for liv og helse, er det politiet som får utlevert IP-adresse direkte
        fra E dialog. Det vil ikke bli samlet inn andre personopplysninger. Det er viktig at ikke du skriver inn
        personopplysninger i chatten, fordi denne tjenesten er anonym. Chatten vil lagres i en uke for kvalitetssikring.

        Har du spørsmål rundt dette, ta kontakt med oss på <a href="mailto:hks@bga.oslo.kommune.no">hks@bga.oslo.kommune.no</a>
      </v-card-text>
      <v-card-text>
        For mer informasjon om Oslo kommunes felles personvernerklæring les mer her: <a
        href="https://www.oslo.kommune.no/personvern-og-informasjonskapsler/">https://www.oslo.kommune.no/personvern-og-informasjonskapsler/</a>
      </v-card-text>

    </v-card>
  </v-container>
</template>

<script>

export default {
  name: 'PrivacyPage',

  data: () => ({
    dates: [],
  }),
};
</script>

<style scoped>

</style>
