import VueRouter from 'vue-router';
import Main from '@/views/Main/';
// import Admin from '@/views/Admin/';
import Posters from '@/views/Posters/';
import Privacy from '@/views/Privacy/';

import Vue from 'vue';

const routes = [
  {
    path: '/',
    component: Main,
    name: 'main-page',
  },
  // {
  //   path: '/admin',
  //   component: Admin,
  //   name: 'admin-page',
  // },
  {
    path: '/plakater',
    component: Posters,
    name: 'posters',
  },
  {
    path: '/personvern',
    component: Privacy,
    name: 'privacy',
  },
  { path: "*", component: Main }
];

Vue.use(VueRouter);

export const router = new VueRouter({
  routes,
  scrollBehavior: function(to) {
    if (to.hash) {
      return {selector: to.hash}
      //Or for Vue 3:
      //return {el: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  },
  mode: 'history',
});


// router.beforeEach((to, from, next) => {
//
// });
export default router;
