<template>
  <v-container
    fluid
    id="main-container">
    <v-card class="elevation-0 offset-md-2 offset-sm-0 mt-12 white">
      <v-img
        id="vertical-logo"
        class="d-flex float-end d-none d-md-flex d-sm-none d-xs-none"
        :src="verticalLogoSrc"
        max-width="200"
      ></v-img>
      <v-img
        :src="mainLogo"
        max-width="550"
      ></v-img>
      <p class="text-sm-h6 text-md-h4 grey--text">Mandag og torsdag 17:00 - 20:00</p>
      <p class="text-h5 error--text mt-12" v-if="showNotice">
        {{ notice }}
      </p>

      <template v-if="!chatIsOpen">
        <p class="text-h5 mt-12 mb-1 primary-blue">Chatten åpner om</p>
        <vue-countdown :time="time" :interval="100" v-slot="{ days, hours, minutes }">
          <div class="row mb-3 ml-0 primary-pink">
            <div class="mr-3"><span class="count-down-text">{{ days }}</span> dager</div>
            <div class="mr-3"><span class="count-down-text">{{ hours }}</span> timer</div>
            <div class="mr-3"><span class="count-down-text">{{ minutes }}</span> minutter</div>
          </div>
        </vue-countdown>

      </template>
      <template v-else>
        <p class="text-h4 mt-12 mb-1 primary-blue">Chatten er åpen!</p>
      </template>
      <p class="body-1 mt-12">Hos oss vil du møte trygge voksne som er fagpersoner med sexologisk videreutdanning eller
        transkompetanse.<br>
        Vi svarer på spørsmål om kjønn og kjønnsidentitet fra deg uansett hvor du bor i landet.
      </p>


      <p class="text-h6 mt-12 mb-1"><a class="hks-link body-1 grey--text darken-2"
                                       href="https://www.oslo.kommune.no/helse-og-omsorg/helsetjenester/helsestasjon-og-vaksine/helsestasjon-for-ungdom-hfu/helsestasjon-for-kjonn-og-seksualitet-hks/#gref">
        Et tilbud levert av Helsestasjon for kjønn og
        seksualitet.
      </a></p>

      <a
        href="https://www.oslo.kommune.no/helse-og-omsorg/helsetjenester/helsestasjon-og-vaksine/helsestasjon-for-ungdom-hfu/helsestasjon-for-kjonn-og-seksualitet-hks/#gref">
        <v-img
          max-width="300"
          :src="hksLogo"
        ></v-img>
      </a>

    </v-card>

  </v-container>
</template>
<script>

import VueCountdown from '@chenfengyuan/vue-countdown';
import moment from 'moment';

export default {
  name: 'MainHeader',
  components: {VueCountdown},
  data: () => ({
    verticalLogoSrc: require('../assets/img/header/vertical_logo.png'),
    hksLogo: require('../assets/img/header/hks.png'),
    mainLogo: require('../assets/img/header/ichatten.png'),
    now: undefined,
    nextOpenDay: undefined,
    chatOpen: false,
    showNotice: false,
    notice: 'Vi er stengt 29/6 pga Pride, og sommerstengt i perioden 7/7-30/7'
  }),
  computed: {
    chatIsOpen() {
      return this.chatOpen;
    },
    time() {
      return this.nextOpenDay - this.now;
    }
  },
  methods: {
    getNoticeText() {
      this.notice = 'Vi er stengt 29 juni pga Pride, og sommerstengt i perioden 7 - 30 juli';
    }
  },

  beforeMount() {
    const now = new Date();
    let day = now.getDay();
    let hour = now.getHours();
    this.now = now;

    if (day === 1) {

      if (hour >= 17 && hour < 20) {
        this.chatOpen = true;
      } else {
        this.chatOpen = false;

        if (hour < 17) {
          this.nextOpenDay = moment().hour(17).minute(0);
        } else {
          this.nextOpenDay = moment().day(4).hour(17).minute(0);
        }
      }


    } else if (day === 4) {
      if (hour >= 17 && hour < 20) {
        this.chatOpen = true;
      } else {
        this.chatOpen = false;
        if (hour < 17) {
          this.nextOpenDay = moment().hour(17).minute(0);
        } else {
          this.nextOpenDay = moment().add(1, 'weeks').day(1).hour(17).minute(0);
        }
      }
    } else {
      this.chatOpen = false;

      if (day === 0) {
        this.nextOpenDay = moment().add(1, 'day').hour(17).minute(0);
      }
      if (day === 5) {
        this.nextOpenDay = moment().add(1, 'weeks').day(2).hour(17).minute(0);
      }

      if (day === 6) {
        this.nextOpenDay = moment().add(1, 'weeks').day(1).hour(17).minute(0);
      }

      if (day === 7) {
        this.nextOpenDay = moment().add(1, 'day').hour(17).minute(0);
      }
    }

    this.getNoticeText();
  }
};
</script>
<style scoped>
#main-container {
  /*position: relative;*/
  /*height: 100vh*/
}

@media (max-width: 600px) {
  #vertical-logo {
    display: none;
    width: 0;
  }


}

.count-down-text {
  font-size: 2.4rem;
}

.hks-link {
  text-decoration: none;
  /*color: #F5B0D0*/
}


</style>
